import { compact, filter, isEmpty, uniqBy } from "lodash";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Image } from "../../types";
import { setImageParameters } from "../../utils";
import ImageComponent from "../image";

interface AccommodationProps {
  title?: string;
  facilities: string[];
  description?: string;
  image?: Image;
  extraImages?: Image[];
  priceDelta?: number;
  accommodationCode?: string;
  isSelected?: boolean;
  onSelect?: (accommodationCode: string) => void;
}

const Accommodation: React.FC<AccommodationProps> = ({
  title,
  description,
  image,
  extraImages,
  facilities,
}) => {
  const [imageIndex, setImageIndex] = useState<number | undefined>(undefined);
  const images = (
    uniqBy(
      filter(compact([image, ...(extraImages ?? [])]), (img) => !isEmpty(img.url)),
      (img) => img.url
    ) ?? []
  ).map((img) => ({
    ...img,
    url: setImageParameters(img.url ?? "", { width: 1920, mode: "max" }),
  }));

  return (
    <div className="productcard productcard--accommodation">
      {image && image.url && (
        <div className="productcard__header">
          <ImageComponent
            src={setImageParameters(image.url, { width: 300, height: 300, mode: "crop" })}
            alt={image.altText ?? undefined}
            title={image.title ?? undefined}
            count={images.length > 1 ? images.length - 1 : undefined}
            onLinkClick={() => setImageIndex(0)}
          />
          {typeof imageIndex !== "undefined" && (
            <Lightbox
              mainSrc={images[imageIndex].url ?? ""}
              imageTitle={images[imageIndex].title}
              nextSrc={images[(imageIndex + 1) % images.length]?.url ?? ""}
              prevSrc={images[(imageIndex + images.length - 1) % images.length]?.url ?? ""}
              onCloseRequest={() => setImageIndex(undefined)}
              onMovePrevRequest={() =>
                setImageIndex((imageIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
            />
          )}
        </div>
      )}
      <div className="productcard__body">
        <h4 className="productcard__heading">{title}</h4>

        <p className="productcard__description">{description}</p>

        <ul className="list list--usps">
          {facilities.map((facility, i) => (
            <li className="list__item" key={`facility-${i}-${facility}`}>
              {facility}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Accommodation;
