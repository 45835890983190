import {
  TideAccommodation,
  TideItemForContactForm,
  TideItemForCountry,
  TideItemForDestinations,
  TideItemForExcursionConnection,
  TideItemForHotel,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForOfferFormComponent,
  TideItemForQuote,
  TideItemForRegion,
  TideItemForUsp,
  TideItemForUspGroup,
  TideItemForWebsite,
  TideRegime,
  TideItemForExcursion,
  TideItemForNotificationComponentConnection,
  TideCountry,
  Maybe,
} from "../types";
import { clamp, compact, first, isEmpty, isNil, some } from "lodash";
import {
  generatePath,
  getChildAccomodations,
  getChildLinkedExcursions,
  getChildNavigationLinks,
  mapMinDurationType,
} from "../utils";

import AccommodationsGrid from "../components/accommodations-grid";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import GalleryGrid from "../components/gallery-grid";
import Hero from "../components/hero";
import HighlightFrame from "../components/highlight-frame";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import ProductInfo from "../components/product-info";
import ProductIntro from "../components/product-intro";
import Quote from "../components/quote";
import React, { useState } from "react";
import Section from "../components/section";
import StickyBar from "../components/sticky-bar";
import UspGrid from "../components/usp-grid";
import { graphql } from "gatsby";
import OfferForm from "../components/offer-form";
import Copy from "../components/copy";
import ProductCardGrid from "../components/product-card-grid";
import RelatedProductCard from "../components/related-product-card";
import { WindowLocation } from "@reach/router";
import { QSMStoreProvider } from "../app/search-results/qsm-store/context";
import { initialQSMState, qsmReducer } from "../app/search-results/qsm-store/reducer";
import StickyBarActions from "../components/sticky-bar/actions";
import { ProductStoreProvider, useProductStore } from "../app/search-results/product-store/context";
import { initialProductState, productReducer } from "../app/search-results/product-store/reducer";
import ProductPrice from "../components/pricing/product-price";
import RoomFlyIn from "../components/fly-in/fly-in-rooms";
import FlightsFlyIn from "../components/fly-in/fly-in-flights";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface ProductOptionsRequest {
  officeId: number;
  catalogId: number;
  productId: number;
  accommodationCode?: string;
  regimeCode?: string;
  startDate: Date;
  endDate: Date;
  adultCount: number;
  childCount: number;
  serviceType: number;
}

interface HotelPageTemplateProps {
  data: HotelPageData;
  location?: WindowLocation<unknown>;
  pageContext: {
    alternateRoutes: AlternateRoute[];
  };
}

const HotelPageTemplate: React.FC<HotelPageTemplateProps> = ({ data, pageContext }) => {
  const { t, language } = useI18next();
  const notificationBar = first(data.notifications?.nodes);
  const countryOrRegion = data.hotel.parentItem as TideItemForCountry | TideItemForRegion;
  const generalHotelContent = data.hotel?.content?.general;
  const hotelDescription = data.hotel?.content?.description;
  const [maxExcursions, setMaxExcursions] = useState<number>(4);

  const parentCountry =
    countryOrRegion?.parentItem?.templateName === "Country"
      ? (countryOrRegion.parentItem as TideItemForCountry)
      : undefined;
  const destinationsItem = parentCountry
    ? (parentCountry?.parentItem?.parentItem as TideItemForDestinations)
    : (countryOrRegion?.parentItem?.parentItem as TideItemForDestinations);

  const destinationsPath = generatePath(destinationsItem);
  const countryPath = parentCountry ? generatePath(parentCountry, destinationsPath) : "";
  const countryOrRegionPath = generatePath(
    countryOrRegion,
    countryPath === "" ? destinationsPath : countryPath
  );
  const hotelPath = generatePath(data.hotel, countryOrRegionPath);

  const highlights = generalHotelContent?.highlights?.split(/[;\r\n]+/);
  const quoteContent = (data.hotel.content?.general?.quote as TideItemForQuote)?.content?.general;

  const linkedExcursions = compact(
    getChildLinkedExcursions(data.hotel).map(
      (linkedExcursion) =>
        linkedExcursion.content?.general?.excursion as TideItemForExcursion | null | undefined
    )
  );

  const getCountryName = (country: Maybe<TideCountry> | undefined) =>
    country?.localizedNames?.find((ln) => ln?.languageCode === language)?.value ?? country?.name;

  return (
    <QSMStoreProvider
      initialState={{ ...initialQSMState, product: data.hotel }}
      reducer={qsmReducer}
    >
      <ProductStoreProvider
        initialState={{
          ...initialProductState,
          estimatedPrice: {
            defaultPrice: generalHotelContent?.estimatedPrice ?? undefined,
            promoPrice: generalHotelContent?.estimatedPromoPrice ?? undefined,
          },
        }}
        reducer={productReducer}
      >
        <Layout>
          <Seo
            title={compact([generalHotelContent?.titlePrefix, generalHotelContent?.title]).join(
              " "
            )}
            seoTitle={data.hotel.content?.seo?.seoTitle ?? undefined}
            seoDescription={data.hotel.content?.seo?.seoDescription ?? undefined}
            seoKeywords={data.hotel.content?.seo?.seoKeywords ?? undefined}
          />
          <RoomFlyIn product={data?.hotel} isRoundTrip={false} />
          <FlightsFlyIn
            destination={
              (!isEmpty(countryOrRegion.content?.general?.title)
                ? countryOrRegion.content?.general?.title
                : countryOrRegion.name) ?? ""
            }
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
              backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
            />
          )}
          <Navbar
            alternateRoutes={pageContext.alternateRoutes}
            mainNavigationSection={data.mainNavigationSection}
            fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
            fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
            socialNavigationSection={data.socialNavigationSection}
            legalNavigationSection={data.legalNavigationSection}
            phone={data.website?.content?.contact?.phone ?? ""}
            destinations={data.navigationDestinations}
          />
          <StickyBar
            navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
            actionsComponent={<StickyBarActions />}
          />
          <Hero
            type="product"
            titlePrefix={generalHotelContent?.titlePrefix ?? undefined}
            title={generalHotelContent?.title ?? undefined}
            imageSrc={generalHotelContent?.headerImage?.url ?? undefined}
          />
          <Breadcrumb
            items={compact([
              { id: "home", title: "Home", url: "/" },
              {
                id: "destinations",
                title: destinationsItem.content?.general?.title ?? "",
                url: destinationsPath,
              },
              parentCountry && {
                id: "country",
                title:
                  (!isEmpty(parentCountry.content?.general?.title)
                    ? parentCountry.content?.general?.title
                    : parentCountry.name) ?? "",
                url: countryPath,
              },
              {
                id: "countryOrRegion",
                title:
                  (!isEmpty(countryOrRegion.content?.general?.title)
                    ? countryOrRegion.content?.general?.title
                    : countryOrRegion.name) ?? "",
                url: countryOrRegionPath,
              },
              {
                id: "hotel",
                title:
                  (!isEmpty(generalHotelContent?.title)
                    ? generalHotelContent?.title
                    : data.hotel.name) ?? "",
                url: hotelPath,
              },
            ])}
          />
          <Section>
            {generalHotelContent && (
              <ProductIntro
                title={generalHotelContent.title ?? ""}
                introduction={generalHotelContent.introduction ?? ""}
                introductionTitle={
                  generalHotelContent.introductionTitle &&
                  generalHotelContent.introductionTitle != ""
                    ? generalHotelContent.introductionTitle
                    : undefined
                }
                destination={
                  (!isEmpty(countryOrRegion.content?.general?.title)
                    ? countryOrRegion.content?.general?.title
                    : countryOrRegion.name) ?? ""
                }
                rating={generalHotelContent?.stars ?? undefined}
                mediaComponent={
                  <GalleryGrid
                    images={
                      generalHotelContent?.images?.map((image) => ({ src: image?.url ?? "" })) ?? []
                    }
                  />
                }
                pricingComponent={<ProductPrice loaderText={t("LOADING_PACKAGE")} />}
              />
            )}
          </Section>
          {generalHotelContent?.usps && generalHotelContent.usps.length > 0 && (
            <Section>
              <UspGrid usps={generalHotelContent?.usps as TideItemForUsp[]} />
            </Section>
          )}
          {highlights &&
            highlights.length > 0 &&
            some(highlights, (highlight) => !isEmpty(highlight)) && (
              <Section>
                <HighlightFrame highlights={highlights} />
              </Section>
            )}
          {!isNil(quoteContent) && (
            <Section>
              <Quote
                image={quoteContent?.image?.url ?? undefined}
                title={quoteContent?.title ?? undefined}
                body={quoteContent?.body ?? undefined}
                signature={quoteContent?.signature ?? undefined}
                author={quoteContent?.author ?? undefined}
                job={quoteContent?.job ?? undefined}
              />
            </Section>
          )}
          <Section>
            <ProductInfo
              title={t("ABOUT_HOTEL")}
              blocks={[
                { title: t("LOCATION"), description: hotelDescription?.location ?? undefined },
                {
                  title: t("REGIMES"),
                  description: hotelDescription?.mealRegimes ?? undefined,
                },
                { title: t("FACILITIES"), description: hotelDescription?.facilities ?? undefined },
                {
                  title: t("RESTAURANTS_AND_BARS"),
                  description: hotelDescription?.restaurants ?? undefined,
                },
                {
                  title: t("EXTRAS"),
                  description: hotelDescription?.extras ?? undefined,
                },
                {
                  title: t("ACCOMMODATIONS"),
                  description: hotelDescription?.accommodations ?? undefined,
                },
              ]}
            />
          </Section>
          <Section>
            <AccommodationsGrid
              title={t("ROOM_TYPES")}
              accomodations={getChildAccomodations(data.hotel)
                .filter(
                  (accommodationItem) => !isEmpty(accommodationItem.content?.general?.productcode)
                )
                .map((accommodationItem) => ({
                  code: accommodationItem.content?.general?.productcode as string,
                  title: accommodationItem.content?.general?.title ?? "",
                  description: accommodationItem.content?.general?.description ?? "",
                  facilities:
                    accommodationItem.content?.description?.facilities?.split(/[;\r\n]+/) ?? [],
                  image: accommodationItem.content?.description?.picture ?? undefined,
                  galleryImages: compact(accommodationItem.content?.description?.extraImages),
                }))}
            />
          </Section>
          {[...linkedExcursions, ...data.relatedExcursions?.nodes].length > 0 && (
            <Section>
              <Copy centered title={t("RELATED_EXCURSIONS")} />
              <ProductCardGrid
                columns={clamp(
                  [...linkedExcursions, ...data.relatedExcursions.nodes].length ?? 0,
                  2,
                  4
                )}
                itemsComponent={
                  <>
                    {[...linkedExcursions, ...data.relatedExcursions.nodes]
                      .slice(0, maxExcursions)
                      .map((excursion) => (
                        <RelatedProductCard
                          key={excursion.id}
                          productName={excursion.content?.general?.title ?? ""}
                          destinationName={
                            getCountryName(excursion.content?.general?.product?.country) ??
                            undefined
                          }
                          description={excursion.content?.general?.introduction ?? undefined}
                          summary={excursion.content?.general?.summary ?? undefined}
                          headerImage={excursion.content?.general?.headerImage ?? undefined}
                          images={compact(excursion.content?.general?.images)}
                          isExcursion={true}
                          price={{
                            defaultPrice: excursion.content?.general?.estimatedPrice ?? undefined,
                            promoPrice:
                              excursion.content?.general?.estimatedPromoPrice ?? undefined,
                          }}
                        />
                      ))}
                  </>
                }
                actionsComponent={
                  [...linkedExcursions, ...data.relatedExcursions.nodes].length > 4 ? (
                    <button
                      type="button"
                      className="cta"
                      title={maxExcursions == 4 ? t("VIEW_RELATED_EXCURSIONS") : t("HIDE")}
                      onClick={() => setMaxExcursions(maxExcursions == 4 ? 99 : 4)}
                    >
                      {maxExcursions == 4 ? t("VIEW_RELATED_EXCURSIONS") : t("HIDE")}
                    </button>
                  ) : (
                    <></>
                  )
                }
              />
            </Section>
          )}
          <PageComponents componentItems={compact(data.hotel.childItems)} />
          <OfferForm
            confirmationEmailTemplate={
              data.offerForm?.content?.externalMail?.templateConfirmationMail?.tideId
            }
            emailMessage={data.offerForm?.content?.internMail?.message ?? undefined}
            emailSubject={data.offerForm?.content?.internMail?.subject ?? undefined}
            introText={data.offerFormComponent?.content?.general?.description ?? undefined}
            title={data.offerFormComponent?.content?.general?.title ?? undefined}
            newsletterTag={
              data.offerFormComponent?.content?.tags?.newsletterTag?.tideId ?? undefined
            }
            offerTag={data.offerFormComponent?.content?.tags?.offerTag?.tideId ?? undefined}
            productName={
              generalHotelContent?.product?.name ?? generalHotelContent?.title ?? undefined
            }
            productCode={generalHotelContent?.product?.code ?? undefined}
            minDuration={generalHotelContent?.product?.minDuration ?? undefined}
            minDurationType={mapMinDurationType(
              generalHotelContent?.product?.minDurationType ?? undefined
            )}
            accommodations={generalHotelContent?.product?.accommodations as TideAccommodation[]}
            regimes={generalHotelContent?.product?.regimes as TideRegime[]}
            privacyPath={
              data.offerFormComponent?.content?.general?.privacyDisclaimerLink ?? undefined
            }
            privacyText={
              data.offerFormComponent?.content?.general?.privacyDisclaimerLinkText ?? undefined
            }
            confirmationText={
              data.offerFormComponent?.content?.confirmation?.description ?? undefined
            }
            confirmationTitle={data.offerFormComponent?.content?.confirmation?.title ?? undefined}
          />
          <Footer
            footerNavigationSection={data.footerNavigationSection}
            socialNavigationSection={data.socialNavigationSection}
            legalNavigationSection={data.legalNavigationSection}
            address={data.website?.content?.contact?.address ?? ""}
            phone={data.website?.content?.contact?.phone ?? ""}
            email={data.website?.content?.contact?.email ?? ""}
            uspGroup={data.footerUspGroup}
            newsletterForm={data.newsletterForm}
          />
        </Layout>
      </ProductStoreProvider>
    </QSMStoreProvider>
  );
};

interface HotelPageData extends LookupQueryData {
  hotel: TideItemForHotel;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  relatedExcursions: TideItemForExcursionConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  offerForm: TideItemForContactForm;
  offerFormComponent: TideItemForOfferFormComponent;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query HotelPageQuery(
    $id: String
    $productCountryId: String
    $productOordId: String
    $productRegionId: String
    $productLocationId: String
    $language: String
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    hotel: tideItemForHotel(id: { eq: $id }) {
      id
      content {
        general {
          title
          titlePrefix
          headerImage {
            url
          }
          introductionTitle
          introduction
          stars
          images {
            url
          }
          usps {
            ... on TideItemForUsp {
              id
              content {
                general {
                  icon
                  text
                }
              }
            }
          }
          product {
            tideId
            code
            minDuration
            minDurationType
            accommodations {
              code
              id
              tideId
              name
              occupancies {
                maxAdults
                maxChildAge
                maxChildren
                maxPax
                minAdults
                minAge
                minChildAge
                minPax
              }
            }
            regimes {
              id
              tideId
              name
              code
              localizedNames {
                languageCode
                value
              }
            }
          }
          highlights
          quote {
            ... on TideItemForQuote {
              content {
                general {
                  title
                  body
                  signature
                  author
                  job
                  image {
                    url
                  }
                }
              }
            }
          }
          estimatedPrice
          estimatedPromoPrice
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
        description {
          location
          mealRegimes
          facilities
          restaurants
          extras
          accommodations
        }
        accommodation {
          accommodationDescription
          accommodationTitle
        }
      }
      ...productPathFragment
      ...pageComponentsFragment
      childItems {
        id
        name
        templateName
        ... on TideItemForAccomodation {
          id
          name
          content {
            description {
              picture {
                url
                title
                altText
              }
              extraImages {
                url
                title
                altText
              }
              facilities
            }
            general {
              description
              productcode
              title
            }
          }
        }
        ... on TideItemForLinkedExcursion {
          content {
            general {
              excursion {
                id
                name
                ... on TideItemForExcursion {
                  content {
                    general {
                      title
                      introduction
                      summary
                      headerImage {
                        url
                      }
                      images {
                        url
                        altText
                        title
                      }
                      estimatedPrice
                      estimatedPromoPrice
                      product {
                        country {
                          name
                          localizedNames {
                            languageCode
                            value
                          }
                        }
                        minDuration
                        minDurationType
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    relatedExcursions: allTideItemForExcursion(
      filter: {
        language: { eq: $language }
        content: {
          general: {
            noAutomaticLinking: { ne: true }
            product: {
              countryId: { eq: $productCountryId }
              oordId: { eq: $productOordId }
              regionId: { eq: $productRegionId }
              locationId: { eq: $productLocationId }
            }
          }
        }
      }
    ) {
      nodes {
        id
        ... on TideItemForExcursion {
          name
          content {
            general {
              title
              introduction
              summary
              headerImage {
                url
              }
              images {
                url
                altText
                title
              }
              estimatedPrice
              estimatedPromoPrice
              product {
                country {
                  name
                  localizedNames {
                    languageCode
                    value
                  }
                }
                minDuration
                minDurationType
              }
            }
          }
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
    offerForm: tideItemForContactForm(name: { eq: "Offer" }, language: { eq: $language }) {
      content {
        externalMail {
          templateConfirmationMail {
            id
            tideId
            name
          }
        }
        internMail {
          message
          subject
        }
      }
      id
      name
    }

    offerFormComponent: tideItemForOfferFormComponent(
      name: { eq: "Offer Product Form" }
      language: { eq: $language }
    ) {
      content {
        general {
          description
          title
          privacyDisclaimerLink
          privacyDisclaimerLinkText
        }
        confirmation {
          description
          title
        }
        tags {
          newsletterTag {
            tideId
            name
          }
          offerTag {
            tideId
            name
          }
        }
      }
      id
      name
    }
  }
`;

export default HotelPageTemplate;
