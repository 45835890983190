import React from "react";
import { Image } from "../../types";
import Accommodation from "./accommodation";

export interface Accommodation {
  code: string;
  title: string;
  description: string;
  facilities: string[];
  image?: Image;
  galleryImages?: Image[];
}

interface AccommodationsGridProps {
  title?: string;
  description?: string;
  accomodations: Accommodation[];
}

const AccommodationsGrid: React.FC<AccommodationsGridProps> = ({
  accomodations,
  title,
  description,
}) => {
  return (
    <>
      <div className="copy copy--centered">
        <h2 className="copy__heading">{title}</h2>
        <p className="copy__description">{description}</p>
      </div>
      <div className="productcard-grid productcard-grid--2">
        <div className="productcard-grid__items">
          {accomodations.map((accommodation) => (
            <Accommodation
              key={accommodation.code}
              accommodationCode={accommodation.code}
              title={accommodation.title}
              description={accommodation.description}
              image={accommodation.image}
              extraImages={accommodation.galleryImages}
              facilities={accommodation.facilities}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AccommodationsGrid;
